import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

const STACKSO_API_URL =
  "https://gx18vgspqf.execute-api.us-west-2.amazonaws.com/production/webhook";
const STACKSO_API_KEY = "LcVH0PWEQQ9fgwocqMfqh8WpmYTlzi6V3de5UHhu";

const submitScoreToStackSo = async (address, points) => {
  const timestamp = Date.now();
  const epoch = Math.floor(timestamp / 10000);
  const uniqueId = `${address}-${epoch}`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-api-key": STACKSO_API_KEY,
    },
    body: JSON.stringify({
      eventType: "played_asteroids",
      associatedAccount: address,
      points: points,
      eventTimestamp: timestamp,
      eventData: {
        uniqueId: uniqueId,
      },
      chainId: 78225,
      pointSystemId: 2923,
      unhashedKey: "6965ef80-7a7a-4d39-b273-e9b69ff18254",
      uniqueId: uniqueId,
      source: "sdk-0.3.1",
    }),
  };
  try {
    const resp = await fetch(STACKSO_API_URL, requestOptions);
    const respJson = await resp.json();
    alert(JSON.stringify(respJson, null, 2));
  } catch (e) {
    console.error(e);
  }
};

const App = () => {
  const [address, setAddress] = useState("");
  const [highScore, setHighScore] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await submitScoreToStackSo(address, highScore);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-center mb-4">
        <Col className="text-center">
          <img
            src="./header-meme-2.png"
            alt="Meme"
            style={{ height: "300px" }}
          />
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        <Col md={6}>
          <Card>
            <CardHeader>
              <h4 className="text-center">Spoof your custom score!</h4>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="address">Address</Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    // ENS for lol.eth
                    placeholder="0xFFb6D97Bd1E7B7bd08595096d15037401A1f416B"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    disabled={isSubmitting}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="highScore">High Score</Label>
                  <Input
                    type="number"
                    name="highScore"
                    id="highScore"
                    placeholder="69420"
                    value={highScore}
                    onChange={(e) => setHighScore(e.target.value)}
                    required
                    disabled={isSubmitting}
                  />
                </FormGroup>
                <Button
                  color="primary"
                  className="justify-content-center"
                  block
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner size="sm" className="me-2">
                      Loading...
                    </Spinner>
                  ) : (
                    <>
                      <span className="mr-4">
                        trust me bro, this is my score
                      </span>
                      &nbsp; &nbsp;
                      <img
                        src="./doge.gif"
                        alt="Meme"
                        style={{ height: "25px" }}
                        className="mb-2"
                      />
                    </>
                  )}
                </Button>
                <FormText>
                  Note: This only works for addresses that have minted the
                  Asteroids game NFT.
                </FormText>
              </Form>
            </CardBody>
            <CardFooter>
              Steps:
              <br />
              1. Enter your address, desired high score and click the button.
              <br />
              2. Check your updated score on{" "}
              <a
                href="https://www.arcade.fun/#leaderboard"
                target="_blank"
                rel="noreferrer"
              >
                arcade.fun
              </a>{" "}
              (Asteroids leaderboard).
              <br />
              3. Profit???
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        <Col className="text-center">
          <i>can devs do something???</i>
          <br />
          Checkout this{" "}
          <a
            // TODO: update blogpost link
            href="https://mirror.xyz/stackrlabs.eth"
            target="_blank"
            rel="noreferrer"
          >
            blogpost ↗
          </a>{" "}
          on how-to actual "onchain" game :)
        </Col>
      </Row>
    </Container>
  );
};

export default App;
